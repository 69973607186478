<!-- © 2021 Tfarraj -->
<template>
  <div ref="watch">
    <v-skeleton-loader
      type="card-avatar, article, actions"
      :loading="videoLoading"
      tile
      small
    >
      <div ref="watchLoad">
        <v-responsive>
          <div class="shaka">
            <video-player
              ref="videoLoad"
              :license-server="`https://widevine-proxy.appspot.com/proxy`"
              :manifest-url="vurl"
              :poster-url="`${apiUrl}/api/v1/videos/${video.id}/thumbnail`"
              :content-title="`this.video.title`"
              :page-url="currentUrl"
              :ua-status="isMobile()"
              :vid-id="vidId"
              :duration="durationSeconds"
              :vcat-name="vcat"
              :view="this.view"
            />
          </div>
        </v-responsive>
      </div>
    </v-skeleton-loader>
  </div>
</template>

<script>
import Config from '@/config';
import VideoService from '@/services/VideoService';
import VideoPlayer from '@/components/VideoPlayer.vue';
import DateMixin from '@/mixins/DateMixin.ts';

let stage = '';

export default {
  mixins: [DateMixin],
  data: () => ({
    loading: false,
    errored: false,
    videoLoading: true,
    video: {},
    view: {},
    truncate: true,
    currentUrl: '',
    siteName: Config.siteName,
    apiUrl: Config.apiUrl,
    vurl: '',
    vidId: '',
    durationSeconds: '',
    vcat: '',
    sStatus: '',
    fullScreenMode: false,
  }),
  methods: {
    openFullscreen() {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    async getVideo(id) {
      this.errored = false;
      this.videoLoading = true;
      this.video = {};
      try {
        const video = await VideoService.getById(id);
        this.vidId = id;

        if (!video) return this.$router.push('/');
        this.video = video.data;
        stage = this.video.stage;
        this.durationSeconds = video.data.durationSeconds;
        this.vurl = `${this.apiUrl}/api/v1/videos/${id}/video`;

        this.currentUrl = window.location.pathname;
        if (stage === 'live') {
          this.sStatus = 'Live';
        } else {
          this.sStatus = '';
        }
      } catch (err) {
        this.errored = true;
      } finally {
        if (Object.keys(this.view).length !== 0) {
          localStorage.setItem(
            this.video._id,
            (this.view.watchPercentage * this.video.durationSeconds) / 100,
          );
        } else {
          await this.createView(this.video._id, 0);
        }

        this.videoLoading = false;
        // Added to enable counts for self reload of Watch Page.
        // See Repeat Below. Causes Double count on Arrival from other pages.
        // Still cannot be implemented to count views when not logged in.
      }
      if (
        this.video.createdBy.toString() !== this.video.createdBy.toString() &&
        this.video.status !== 'public'
      ) {
        return this.$router.push('/');
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        )
      ) {
        return true;
      }
      return false;
    },
    async createView(id, watchPercentage) {
      if (!this.getIsAuthenticated) {
        return;
      }
      const view = await VideoService.createView(id, {
        watchPercentage,
      }).catch((err) => {
        // console.log(err);
      });
      if (!view) return;

      this.view = view.data;
    },
    play(e) {
      // console.log(e);
    },
    actions() {
      this.getVideo();
    },
  },
  components: {
    VideoPlayer,
  },
  mounted() {
    const videoId = this.$route.params.id;
    this.getVideo(videoId);
  },
};
</script>

<style lang="scss">
.v-card__text,
.v-card__title {
  word-break: normal;
}
.streamstatus {
  color: #bd0404 !important;
  padding-left: 10px;
}
.grey-color {
  color: #7f7f7f !important;
}
#btns {
  border-bottom: 1px solid #e0d8d8;
}
button.v-btn.remove-hover-bg {
  background-color: initial !important;

  &:hover {
    background-color: #f9f9f9;
  }
}
.shaka {
  margin: 0 auto;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: top;
  text-align: center;
  background-color: #000000;
}
.shaka-controls-container {
  opacity: 1;
}
.shaka-video-container {
  width: 100%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.max-w-full {
  max-width: 100%;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.video-ratio {
  aspect-ratio: 16 / 9;
}
</style>
